import React from 'react';

const CommonPageHeader = ({title,subtitle}) => {
   return (
      <>
         <section className="breadcrumb-bg page_header_bg">
            <div className="container">
               <div className="row">
                  <div className="col-lg-9 col-md-9">
                     <div className="page-title">
                        {/*<p className="small-text pb-15">We are here for your care.</p>*/}
                        <h1
                            data-aos="fade-right"
                            data-aos-offset="300"
                            data-aos-easing="ease-in-sine"
                            data-aos-once="true"
                        >{title}</h1>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonPageHeader;
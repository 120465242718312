import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import Layout from "@components/layout";
import ContactArea from "@components/contact/ContactArea/ContactArea";
import ContactMap from "@components/contact/ContactMap/ContactMap";
import CommonPageHeader from "@components/common/CommonPageHeader/CommonPageHeader";

const ContactPage: React.FC<PageProps> = () => {
    return (
        <Layout>
            <CommonPageHeader title={"Kontakt"} subtitle={""} />
            <ContactArea/>
            <ContactMap/>
        </Layout>
    )
}

export default ContactPage;

export const Head: HeadFC = () => <title>Kontakt - Fizjoproject Kiekrz</title>

import React from 'react';
import {useStrapiStatic} from "@hooks/useStrapiStatic";

const ContactArea = () => {
    return (
        <>
            <section className="contact-area pt-120 pb-90" data-background="assets/img/bg/bg-map.png">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4  col-lg-4 col-md-4 d-flex flex-1">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-phone"></i>
                                <h3>Umów się</h3>
                                <p>{ useStrapiStatic('phone_number') }</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 d-flex flex-1">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-envelope"></i>
                                <h3>Napisz wiadomość</h3>
                                <p>{ useStrapiStatic('email') }</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 d-flex flex-1">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-map-marker-alt"></i>
                                <h3>Przyjdź na wizytę</h3>
                                <p>{useStrapiStatic('address')}</p>
                                <p className={"opacity-50 text-start fs-18"} dangerouslySetInnerHTML={ {__html: useStrapiStatic('work_time')} }></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactArea;
import React from 'react';

const ContactMap = () => {
    return (
        <>
            <section className="map-area">

                    <div style={{ width: '100%' }}>
                        <iframe title="Contact" src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d1215.0591257811159!2d16.785018301124005!3d52.4769946054983!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x47044114fb386973%3A0xcb29d6323456f16b!2sChojnicka%2054%2C%2062-090%20Pozna%C5%84!3m2!1d52.4769854!2d16.7854465!5e0!3m2!1spl!2spl!4v1672175852519!5m2!1spl!2spl" style={{ width: '100%' }} height={600} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>

            </section>
        </>
    );
};

export default ContactMap;